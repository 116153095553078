import axios from 'axios'
import { itemsPerPage } from '@/helpers/constants'
import convertToFormData from '@/helpers/convertToFormData'
const state = () => ({
  perPageItems: itemsPerPage,
  currentPage: 1,
  loading: false,
  sorting: {
    by: 'date',
    order: 'DESC'
  },
  items: [],
  total: null
})

export default {
  namespaced: true,
  state,
  getters: {
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    toggleLoading (state, status) {
      state.loading = status
    },
    setItems (state, { items, total }) {
      state.items = items
      state.total = total
    },
    setPage (state, { page, itemsPerPage }) {
      state.currentPage = page
      state.perPageItems = itemsPerPage
    },
    updateSort (state, data) {
      state.sorting = data
    },
    resetItems (state) {
      state.currentPage = 1
      state.items = []
    }
  },
  actions: {
    loadReports ({ state, commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('items', state.perPageItems)
        const { by, order } = state.sorting
        params.append('sortBy', by)
        params.append('sort', order)
        params.append('page', state.currentPage)

        const filters = rootGetters['reportFilter/finalFilter']
        Object.keys(filters).forEach(key => {
          const val = filters[key]
          if (Array.isArray(val)) {
            val.forEach((item) => {
              params.append(`${key}[]`, item)
            })
          } else if (val) {
            params.append(key, val)
          }
        })
        commit('toggleLoading', true)
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}reports?${params.toString()}`)
          .then(({ data }) => {
            commit('toggleLoading', false)
            if (data.status === 'success') {
              let { data: items, total } = data.data
              items = items.map(item => {
                let { agencies, bookers, freelancers, projects, clients, status } = item
                agencies = JSON.parse(agencies || '[]')
                bookers = JSON.parse(bookers || '[]')
                freelancers = JSON.parse(freelancers || '[]')
                projects = JSON.parse(projects || '[]')
                clients = JSON.parse(clients || '[]')
                status = JSON.parse(status || '[]')
                return {
                  ...item,
                  agencies,
                  bookers,
                  freelancers,
                  projects,
                  clients,
                  status
                }
              })
              commit('setItems', { items, total })
              resolve({ items, total })
            } else {
              reject(new Error('Error loading reports'))
            }
          }).catch(() => {
            commit('toggleLoading', false)
            reject(new Error('Error loading reports'))
          })
      })
    },
    async updateReportsPage ({ commit, dispatch }, { page, itemsPerPage }) {
      await commit('setPage', { page, itemsPerPage })
      await dispatch('loadReports')
    },
    async sortReports ({ commit, dispatch }, sorting) {
      await commit('updateSort', sorting)
      await dispatch('loadReports')
    },
    reloadReports ({ commit, dispatch }) {
      commit('resetItems')
      dispatch('loadReports')
    },
    saveReport ({ commit, dispatch, rootState, rootGetters }, form) {
      return new Promise((resolve, reject) => {
        const params = convertToFormData(form)

        axios[form.id ? 'put' : 'post'](`${process.env.VUE_APP_AGENCY_API_URL}reports`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              dispatch('loadReports')
              resolve()
            } else {
              reject(new Error('Error saving report'))
            }
          })
          .catch(() => {
            reject(new Error('Error saving report'))
          })
      })
    },
    loadReportDetail ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}reports?id=${id}`)
          .then(({ data }) => {
            let { agencies, bookers, freelancers, projects, clients } = data.data
            agencies = agencies || []
            bookers = bookers || []
            freelancers = freelancers || []
            projects = projects || []
            clients = clients || []
            resolve({ ...data.data, agencies, bookers, freelancers, projects, clients })
          }).catch((error) => {
            reject(error)
          })
      })
    },
    deleteReport ({ commit, dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_AGENCY_API_URL}reports?id=${id}`)
          .then(({ data }) => {
            dispatch('loadReports')
            resolve(data.data)
          }).catch((error) => {
            reject(error)
          })
      })
    },
    downloadReport ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}reports/download?id=${id}`, { responseType: 'blob' })
          .then(({ data }) => {
            if (data) {
              resolve(data)
            } else {
              reject(new Error('Error downloading report'))
            }
          }).catch(() => {
            reject(new Error('Error downloading report'))
          })
      })
    },
    loadCfoReports ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}finance/dashboard`)
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve(data.data)
            } else {
              reject(new Error('Error getting report'))
            }
          }).catch(() => {
            reject(new Error('Error getting report'))
          })
      })
    },
    downloadCfoReports ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}finance/dashboard/download`, { responseType: 'blob' })
          .then(({ data }) => {
            if (data) {
              resolve(data)
            } else {
              reject(new Error('Error downloading cfo reports'))
            }
          }).catch(() => {
            reject(new Error('Error downloading cfo reports'))
          })
      })
    }
  }
}
