import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '../store'

axios.interceptors.request.use(async config => {
  Vue.$log.debug(config)
  if (!config.url.includes(process.env.VUE_APP_KEYCLOAK_URL)) {
    const { accessToken, realm, activeGroup } = store.state?.auth || {}
    if (accessToken) {
      config.headers.common.Authorization = `Bearer ${accessToken}`
      config.headers.realmName = process.env[`VUE_APP_KEYCLOAK_${realm.toUpperCase()}_REALM`]
      config.headers.languageCode = store.state?.lang?.locale
      config.headers.countryCode = 'DE' // TODO: Should be dynamic when Aladin is released in other countries
      if (activeGroup) {
        config.headers.group = activeGroup
      }
    }
  }
  return config
})

// Retry queue, each item will be a function to be executed
window.requests = []

axios.interceptors.response.use(response => {
  return response
}, error => {
  const { response } = error
  Vue.$log.debug('axios error response', response)
  if (response.status === 401) {
    const config = response.config
    if (!store.state.isTokenUpdating) {
      return store.dispatch('auth/updateToken').then(accessToken => {
        config.headers.Authorization = `Bearer ${accessToken}`
        config.baseURL = ''
        return axios(config)
      })
    } else {
      // token is being refreshed and a promise that resolve has not been executed is returned
      return new Promise((resolve) => {
        // Put resolve in the queue, save it in a function form, and execute it directly after token refreshes
        window.requests.push((token) => {
          config.baseURL = ''
          config.headers.Authorization = `Bearer ${token}`
          resolve(axios(config))
        })
      })
    }
  }
  if (response.status === 400) {
    return Promise.resolve(response)
  }
  if (response.status >= 500) {
    store.commit('addMessage', { messageKey: 'responseMessages.error.unknown' })
    // const message = response?.data?.message
    // store.commit('addMessage', message && response.status !== 500 ? { message } : { messageKey: 'responseMessages.error.unknown' })
  }
  return Promise.reject(error)
})

Vue.use(VueAxios, axios)
