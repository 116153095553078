<template>
  <div :class="{ 'w-100': !$attrs.inline }">
    <ValidationProvider
      ref="provider"
      :tag="$attrs.inline ? 'span' : 'div'"
      :rules="rules"
      :name="$attrs.name"
      :vid="id"
      :debounce="500"
      v-slot="{ errors }"
    >
      <input
        type="hidden"
        v-model.number="formatted"
      />
      <TextField
        v-bind="$attrs"
        type="text"
        rules=""
        hide-details
        :error="!!errors.length || !!customErrors.length"
        :placeholder="$t('form.placeholders.number')"
        v-model="input"
        @input="formatInput"
      />
      <span
        v-if="showErrors && (errors.length || customErrors.length)"
        class="pl-2 label-1 error--text"
      >{{ customErrors.length ? customErrors[0] : errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import amountToLocaleString from '@/helpers/amountToLocaleString'
// import { double } from '@/helpers/regexRules'

export default {
  props: {
    value: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    const id = `${this.$attrs.name || ''}${this._uid}${new Date().getTime()}`
    return {
      id,
      input: null,
      formatted: this.value,
      customErrors: []
    }
  },
  computed: {
    ...mapState('lang', ['locale']),
    showErrors () {
      return !this.$attrs['hide-details'] && this.$attrs['hide-details'] !== ''
    },
    rules () {
      const rules = this.$attrs.rules
      const isDisabled = this.$attrs.disabled === true || this.$attrs.disabled === ''
      if (!isDisabled && typeof this.value === 'number' && !isNaN(this.value) && !Number.isInteger(this.value)) {
        const decimals = this.formatted.split('.')[1].length
        if (decimals > 2) {
          if (typeof rules === 'object') {
            rules.double = { decimals: 2 }
            return rules
          }
          return `${rules ? `${rules}|` : ''}double:2`
        }
      }
      return rules
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        if (!this.value && !this.input) {
          return
        }
        const disabled = this.$attrs.disabled
        const isDisabled =
          typeof disabled !== 'undefined'
            ? !!disabled || disabled === ''
            : false
        const readonly = this.$attrs.readonly
        const isReadonly =
          typeof readonly !== 'undefined'
            ? !!readonly || readonly === ''
            : false
        const addZeros = isDisabled || isReadonly
        const converted = amountToLocaleString({
          amount: this.value || '',
          locale: this.locale,
          includeCurrency: false,
          decimalZeros: addZeros
        })
        const decimalSeparator = this.locale === 'en' ? '.' : ','
        const withSeparator = `${converted}${decimalSeparator}`
        const withZero = `${withSeparator}0`
        const withTwoZero = `${withZero}0`
        if (
          converted !== this.input &&
          ![withSeparator, withZero, withTwoZero].includes(this.input)
        ) {
          this.input = converted
          const formatted = converted ? this.localeStringToNumber() : converted
          this.formatted = formatted
          if (this.$refs.provider) {
            this.$refs.provider.syncValue(formatted)
            this.validateField(formatted)
          }
        }
      }
    },
    rules: {
      deep: true,
      handler () {
        setTimeout(() => {
          if (this.value) {
            this.validateField(this.formatted)
          }
        }, 600)
      }
    }
  },
  methods: {
    async formatInput () {
      if (!this.input && this.input !== '0') {
        this.formatted = this.input
        this.$emit('input', this.input)
        return this.validateField(this.input)
      }

      const decimalSeparator = this.locale === 'en' ? '.' : ','
      const regex = new RegExp(`\\${decimalSeparator}`, 'g')
      const matches = this.input.match(regex)

      const formatted = this.input ? this.localeStringToNumber() : this.input
      this.formatted = formatted
      const value =
        formatted || formatted === '0' ? parseFloat(formatted) : formatted
      this.$emit('input', value)

      if (
        matches?.length === 1 &&
        this.input[this.input.length - 1] === decimalSeparator
      ) {
        return
      }
      this.validateField(formatted)

      if (!this.customErrors.length && value) {
        const parts = formatted.split('.')
        if (parts[0].length < 4) {
          return
        }
        const separator = this.locale === 'en' ? ',' : '.'
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator)
        const result = parts.join(decimalSeparator)
        if (this.input !== result) {
          this.input = result
        }
      }
    },
    localeStringToNumber () {
      return this.locale === 'en'
        ? this.input.replace(/,/g, '')
        : this.input.replace(/\./g, '').replace(/,/g, '.')
    },
    async validateField (value) {
      if (!this.$refs.provider) {
        return
      }
      // if (value && !double.test(value)) {
      //   const message = this.$t('form.messages.double', {
      //     _field_: this.$t(`form.labels.${this.$attrs.name || 'field'}`)
      //   })
      //   this.customErrors = [message]
      //   return
      // }
      const { errors } = await this.$refs.provider.validate(value)
      this.customErrors = errors
    }
  }
}
</script>
