var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"provider",attrs:{"tag":_vm.inline ? 'span' : 'div',"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:[
    'checkbox',
    _vm.themeClass,
    {
      'checkbox__small': _vm.small,
      'checkbox__secondary': _vm.secondary,
      'checkbox__inline': _vm.inline,
      'checkbox__reverse': _vm.reverse,
      'checkbox__disabled': _vm.disabled,
      'checkbox__invalid': errors.length,
      'selected': _vm.isSelected,
      'is-active': _vm.clicked
    }
  ],attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.toggle(true)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.toggle(false)},"focusout":function($event){_vm.clicked = false}}},[_c('div',{staticClass:"checkbox__bg primary"}),_c('div',{staticClass:"checkbox__input primary",class:{ 'opacity-12': !_vm.isSelected, 'opacity-64': _vm.isSelected && _vm.disabled }},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelected),expression:"isSelected"}],attrs:{"small":"","color":"secondary"}},[_vm._v("done")])],1),(_vm.label)?_c('div',{staticClass:"checkbox__label label-1"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }