import Vue from 'vue'
import axios from 'axios'
import convertToFormData from '@/helpers/convertToFormData'
import { projectsFilterTabs, projectStatuses } from '@/helpers/constants'
import { actions, addBookingEvent } from '@/helpers/gtm'
import permissions from '@/helpers/permissions'

const state = () => ({
  projects: [],
  projectDetail: null,
  loading: false,
  currentPage: 1,
  isFinished: false,
  isBookingDialogOpen: false,
  booking: null,
  isBookingProjectEditable: false,
  isBookingFreelancerEditable: false,
  freelancers: [],
  closingBookingItem: null,
  isFlatRatePaymentDialogOpen: false
})

export default {
  namespaced: true,
  state,
  getters: {
    filteredProjects (state, getters, rootState, rootGetters) {
      const filters = rootGetters['projectsFilter/finalFilter']
      let { projects } = state
      if (filters.bookmarked) {
        return projects.filter(item => !!item.bookmark_id)
      }
      if (filters.status.length) {
        projects = projects.filter(({ status }) => filters.status.includes(status))
      }
      if (filters.year.length) {
        projects = projects.filter(({ due_date }) => {
          let status = false
          for (const i in filters.year) {
            const dueDate = due_date.split('-')
            const dueMonth = parseInt(dueDate[1])
            let dueYear = parseInt(dueDate[0])
            if (dueMonth > 6) {
              dueYear++
            }
            if (dueYear === filters.year[i]) {
              status = true
              break
            }
          }
          return status
        })
      }
      if (filters.keyword.length) {
        projects = projects.filter(({ name }) => {
          let status = false
          for (const i in filters.keyword) {
            if (name.toLowerCase().includes(filters.keyword[i].toLowerCase())) {
              status = true
              break
            }
          }
          return status
        })
      }
      return projects
    }
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    resetProjects (state) {
      state.currentPage = 1
      state.isFinished = false
      state.projects = []
    },
    toggleLoading (state, status) {
      state.loading = status
    },
    setProjects (state, data) {
      state.isFinished = state.currentPage >= data.last_page
      if (state.currentPage === 1) {
        state.projects = data.data
      } else {
        state.projects = [...state.projects, ...data.data]
      }
    },
    incrementPage (state) {
      state.currentPage++
    },
    addProject (state, project) {
      state.projects.splice(0, 0, project)
    },
    addProjectDetail (state, project) {
      if (state.projectDetail && state.projectDetail.id === project.id) {
        state.projectDetail = { ...state.projectDetail, ...project }
      } else {
        state.projectDetail = project
      }

      if (state.projects.length) {
        const index = state.projects.findIndex(({ id }) => id === project.id)
        if (index !== -1) {
          const old = state.projects.find(({ id }) => id === project.id)
          state.projects.splice(index, 1, { ...old, ...project })
        }
      }
    },
    resetProjectDetail (state) {
      state.projectDetail = null
    },
    updateProjectStatus (state, { id, status }) {
      const project = state.projects.find(item => id === item.id)
      if (project) {
        project.status = status
      }
      if (state.projectDetail?.id === id) {
        state.projectDetail.status = status
      }
    },
    toggleBookingDialog (state, status) {
      state.isBookingDialogOpen = status
    },
    setBookingData (state, { booking, isBookingProjectEditable = false, isBookingFreelancerEditable = false }) {
      state.booking = booking
      state.isBookingFreelancerEditable = isBookingFreelancerEditable
      state.isBookingProjectEditable = isBookingProjectEditable
    },
    resetBookingData (state) {
      state.isBookingFreelancerEditable = false
      state.isBookingProjectEditable = false
      state.booking = null
    },
    setBookingProject (state, data) {
      Vue.set(state.booking, 'project', data)
    },
    setBookingFreelancer (state, data) {
      Vue.set(state.booking, 'freelancer', data)
    },
    setBookingApprover (state, data) {
      Vue.set(state.booking, 'approver', data)
    },
    setFreelancers (state, data) {
      state.freelancers = data
    },
    addProjectBooking (state, booking) {
      if (state.projectDetail?.id === booking.project_id) {
        state.projectDetail.bookings.splice(0, 0, booking)
      } else {
        const project = state.projects.find(({ id }) => booking.project_id === id)
        if (project) {
          project.bookings.splice(0, 0, booking)
        }
      }
    },
    updateProjectBooking (state, booking) {
      if (state.projectDetail?.id === booking.project_id) {
        const index = state.projectDetail.bookings.findIndex(({ id }) => id === booking.id)
        if (index !== -1) {
          state.projectDetail.bookings.splice(index, 1, booking)
        } else {
          state.projectDetail.bookings.splice(0, 0, booking)
        }
      } else {
        const project = state.projects.find(({ id }) => id === booking.project_id)
        if (project) {
          const index = project.bookings.findIndex(({ id }) => id === booking.id)
          if (index !== -1) {
            project.bookings.splice(index, 1, booking)
          } else {
            project.bookings.splice(0, 0, booking)
          }
        }
      }
    },
    toggleFlatRatePaymentDialog (state, status) {
      state.isFlatRatePaymentDialogOpen = status
    },
    updateBookingStatus (state, booking) {
      if (state.booking?.id === booking.id) {
        state.booking.status = booking.status
      }
      if (state.projectDetail?.id === booking.project_id) {
        const item = state.projectDetail.bookings.find(({ id }) => id === booking.id)
        if (item) {
          item.status = booking.status
        } else {
          state.projectDetail.bookings.splice(0, 0, booking)
        }
      }
    },
    updateFlatBookingSplitPayments (state, { bookingId, projectId, amount }) {
      if (state.booking?.id === bookingId) {
        state.booking.amount_left -= amount
      }
      if (state.projectDetail?.id === projectId) {
        const item = state.projectDetail.bookings.find(({ id }) => id === bookingId)
        if (item) {
          item.amount_left -= amount
        }
      }
    },
    setClosingBookingItem (state, booking) {
      state.closingBookingItem = booking
    },
    updateBookmark (state, { id, bookmark }) {
      if (state.projectDetail?.id === id) {
        state.projectDetail.bookmark_id = bookmark
      }
      const project = state.projects.find((item) => item.id === id)
      if (project) {
        project.bookmark_id = bookmark
      }
    }
  },
  actions: {
    loadProjects ({ state, commit, rootGetters }, {
      search,
      start,
      end,
      statuses = [projectStatuses.ongoing, projectStatuses.paused]
    } = {}) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        const isSearch = typeof search !== 'undefined'

        if (isSearch) {
          params.append('items', 50)
          params.append('keyword', search)
          if (Array.isArray(statuses)) {
            statuses.forEach((status, index) => {
              params.append('status[]', status)
            })
          }
          if (start && end) {
            params.append('start_date', start)
            params.append('end_date', end)
          }
          const isAgency = rootGetters['auth/isAgency']
          if (isAgency) {
            params.append('my_projects', true)
          }
        } else {
          const filters = rootGetters['projectsFilter/finalFilter']

          Object.keys(filters).forEach(key => {
            const val = filters[key]
            if (Array.isArray(val)) {
              val.forEach((item, index) => {
                params.append(`${key}[]`, item)
              })
            } else {
              params.append(key, val)
            }
          })

          params.append('page', state.currentPage)

          commit('toggleLoading', true)
        }

        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}project?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              if (!isSearch) {
                commit('setProjects', data.data)
                commit('toggleLoading', false)
              }
              resolve(data.data.data)
            } else {
              reject(new Error('Error loading projects'))
            }
          })
      })
    },
    reloadProjects ({ commit, dispatch }) {
      commit('resetProjects')
      dispatch('loadProjects')
    },
    loadProjectsPage ({ commit, dispatch }) {
      commit('incrementPage')
      dispatch('loadProjects')
    },
    loadMaconomyProjects (commit) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('keyword', '')

        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}project/maconomy?${params.toString()}`)
          .then(({ data }) => {
            resolve(data.data)
          })
      })
    },
    saveProject ({ commit, dispatch, rootState, rootGetters }, form) {
      return new Promise((resolve, reject) => {
        const params = convertToFormData(form)

        axios[form.id ? 'put' : 'post'](`${process.env.VUE_APP_AGENCY_API_URL}project`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              const { bookmarked, ongoing, paused, done } = projectsFilterTabs
              const { selectedTab } = rootState.projectsFilter
              if (form.id) {
                commit('addProjectDetail', data.data)
              } else if (rootGetters['projectsFilter/hasAnyFilterApplied'] || selectedTab === ongoing) {
                data.data.bookings = []
                dispatch('auth/updateToken', null, { root: true })
                commit('addProject', data.data)
              } else if ([bookmarked, paused, done].includes(selectedTab)) {
                dispatch('auth/updateToken', null, { root: true })
                dispatch('projectsFilter/switchTab', ongoing, { root: true })
              }
              resolve()
            } else {
              reject(new Error('Error saving project'))
            }
          })
          .catch(() => {
            reject(new Error('Error saving project'))
          })
      })
    },
    addBookmark ({ commit }, project) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        params.append('project_id', project.id)

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}project/bookmark/add`, params)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('updateBookmark', { id: project.id, bookmark: data.data.id })
              resolve()
            } else {
              reject(new Error('Error adding bookmark'))
            }
          })
      })
    },
    removeBookmark ({ commit }, project) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        params.append('id', project.bookmark_id)

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}project/bookmark/remove`, params)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('updateBookmark', { id: project.id, bookmark: null })
              resolve()
            } else {
              reject(new Error('Error removing bookmark'))
            }
          })
      })
    },
    updateProjectStatus ({ commit }, { id, status }) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        params.append('id', id)
        params.append('status', status)

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}project/status/update`, params)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('updateProjectStatus', { id, status })
              resolve()
            } else {
              reject(new Error('Error updating project status'))
            }
          })
      })
    },
    loadProjectDetail ({ commit }, projectId) {
      return new Promise((resolve, reject) => {
        commit('resetProjectDetail')
        const params = new URLSearchParams()

        params.append('id', projectId)

        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}project?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              const result = data.data
              if (result?.bookings?.length) {
                result.bookings.forEach(booking => {
                  booking.project_id = projectId
                })
              }
              result.allocations = (result.allocations || []).sort((a, b) => {
                const numberA = a.number.toUpperCase()
                const numberB = b.number.toUpperCase()
                if (numberA < numberB) {
                  return -1
                }
                if (numberA > numberB) {
                  return 1
                }
                return 0
              })
              commit('addProjectDetail', result)
              resolve()
            } else {
              reject(new Error('Error loading project detail'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading project detail'))
          })
      })
    },
    openBookingDialog ({ commit }, data) {
      commit('toggleBookingDialog', true)
      commit('setBookingData', data)
    },
    closeBookingDialog ({ commit }) {
      commit('toggleBookingDialog', false)
      commit('resetBookingData')
    },
    saveBookingRequest ({ commit, state, rootState, rootGetters }, form) {
      return new Promise((resolve, reject) => {
        const params = convertToFormData(form)
        axios[form.id ? 'put' : 'post'](`${process.env.VUE_APP_AGENCY_API_URL}booking`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              const booking = {}
              const keys = ['id', 'title', 'booking_code', 'created_by', 'approver_id', 'freelancer_id', 'project_id', 'start_date', 'end_date', 'rate_type', 'status']
              keys.forEach(key => {
                booking[key] = data.data[key]
              })
              booking.project_id = parseInt(booking.project_id)
              const { firstName, lastName } = rootState.auth.data
              const role = rootGetters['auth/currentRole']
              booking.booker = { first_name: firstName, last_name: lastName, role }
              const { email, id, first_name, last_name } = state.booking.freelancer
              booking.freelancer = { email, id, first_name, last_name }
              const { client_name: client, name, image_url: img = '' } = state.booking.project
              booking.project = { client_name: client, name, image_url: img, id: booking.project_id }
              booking.client_name = client
              booking.project_name = name
              booking.project_image_url = img
              booking.agency_name = rootState.agency.profile.name

              addBookingEvent(actions.requestApproval, {
                project: name,
                rate_freq: form.rate_type,
                rate: form.rate,
                days: form.period,
                total_amount: form.total_cost
              })

              if (form.id) {
                commit('updateProjectBooking', booking)
              } else {
                commit('addProjectBooking', booking)
              }

              const isAgency = rootGetters['auth/isAgency']
              if (isAgency) {
                commit('freelancers/updateBookingList', booking, { root: true })
              }
            }
            resolve(data)
          })
          .catch(() => {
            reject(new Error('Error sending booking request'))
          })
      })
    },
    updateBookingEndDate ({ commit, state }, form) {
      return new Promise((resolve, reject) => {
        const params = convertToFormData(form)
        axios.put(`${process.env.VUE_APP_AGENCY_API_URL}booking/extend`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              const booking = { ...state.booking, end_date: form.end_date }
              commit('updateProjectBooking', booking)
              commit('freelancers/updateBookingList', booking, { root: true })
            }
            resolve(data)
          })
          .catch(() => {
            reject(new Error('Error updating booking end date'))
          })
      })
    },
    loadBookingDetail ({ commit, rootGetters, dispatch }, { id, dialog = true }) {
      return new Promise((resolve, reject) => {
        commit('resetBookingData')
        const params = new URLSearchParams()

        params.append('id', id)
        if (dialog) {
          commit('toggleBookingDialog', true)
        }
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}booking?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              const booking = data.data
              booking.rate = booking.rate ? +booking.rate : null
              booking.total_cost = +booking.total_cost
              booking.it_requirements = booking.it_requirements ? JSON.parse(booking.it_requirements) : []
              booking.server_links = booking.server_links ? JSON.parse(booking.server_links) : []
              const loggedInUserId = rootGetters['auth/loggedInUserId']
              if (loggedInUserId === booking.approver_id) {
                if (!rootGetters['auth/hasPermission'](`${permissions.approveBooking}-${id}`)) {
                  dispatch('auth/updateToken', null, { root: true })
                }
              }
              commit('setBookingData', { booking })
              resolve()
            } else {
              reject(new Error('Error loading booking detail'))
            }
          })
      })
    },
    approveBookingRequest ({ dispatch }, id) {
      return dispatch('updateBookingStatus', { data: { booking_id: id }, path: 'approve' })
    },
    acceptBookingRequest ({ dispatch }, data) {
      return dispatch('updateBookingStatus', { data, path: 'accept' })
    },
    declineBookingRequest ({ dispatch }, data) {
      return dispatch('updateBookingStatus', { data, path: 'decline' })
    },
    deleteBookingRequest ({ dispatch }, data) {
      return dispatch('updateBookingStatus', { data, path: 'cancel' })
    },
    closeBooking ({ dispatch }, data) {
      return dispatch('updateBookingStatus', { data, path: 'close' })
    },
    updateBookingStatus ({ commit, rootGetters }, { data, path }) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        Object.keys(data).forEach(key => {
          params.append(key, data[key])
        })

        axios.patch(`${process.env.VUE_APP_AGENCY_API_URL}booking/${path}`, params)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('updateBookingStatus', data.data)
              commit('bookings/updateBookingStatus', data.data, { root: true })
              if (rootGetters['auth/isFreelancer']) {
                commit('freelancer/updateBookingStatus', data.data, { root: true })
              } else {
                commit('freelancers/updateBookingStatus', data.data, { root: true })
              }
            }
            resolve(data)
          })
          .catch(() => {
            reject(new Error('Error updating booking status'))
          })
      })
    },
    loadFlatRateBookingDetail ({ commit }, { id, allocations, projectId }) {
      Promise.all([
        new Promise((resolve, reject) => {
          const params = new URLSearchParams()
          params.append('id', id)
          axios.get(`${process.env.VUE_APP_AGENCY_API_URL}booking?${params.toString()}`)
            .then(({ data }) => {
              if (data.status === 'success') {
                const booking = data.data
                booking.rate = booking.rate ? +booking.rate : null
                booking.rate_per_hour = booking.rate_per_hour ? +booking.rate_per_hour : null
                booking.total_cost = +booking.total_cost
                resolve(booking)
              } else {
                reject(new Error('Error loading flat rate booking detail'))
              }
            })
            .catch(() => {
              reject(new Error('Error loading flat rate booking detail'))
            })
        }),
        new Promise((resolve, reject) => {
          if (allocations) {
            return resolve(allocations)
          }

          const params = new URLSearchParams()
          params.append('id', projectId)

          axios.get(`${process.env.VUE_APP_AGENCY_API_URL}project?${params.toString()}`)
            .then(({ data }) => {
              if (data.status === 'success') {
                resolve(data.data.allocations)
              } else {
                reject(new Error('Error loading project detail'))
              }
            })
            .catch(() => {
              reject(new Error('Error loading project detail'))
            })
        })
      ])
        .then(([booking, projectAllocations]) => {
          booking.projectAllocations = projectAllocations
          commit('setBookingData', { booking })
        })
    },
    toggleFlatRatePaymentDialog ({ commit, dispatch }, data) {
      dispatch('loadFlatRateBookingDetail', data)
      commit('toggleFlatRatePaymentDialog', data.status)
    },
    closeFlatRatePaymentDialog ({ commit }) {
      commit('toggleFlatRatePaymentDialog', false)
      commit('resetBookingData')
    },
    confirmFlatRatePayment ({ commit, rootGetters }, { form, amountToReduce, freelancerId, projectId }) {
      return new Promise((resolve, reject) => {
        const params = convertToFormData(form)
        const method = form.id ? 'put' : 'post'
        const path = form.id ? 'flatrate-payment' : 'booking/flatrate/split'

        axios[method](`${process.env.VUE_APP_AGENCY_API_URL}${path}`, params)
          .then(({ data }) => {
            if (data.status === 'success') {
              const updateData = { bookingId: form.booking_id, freelancerId, projectId, amount: amountToReduce }
              commit('updateFlatBookingSplitPayments', updateData)
              commit('bookings/updateFlatBookingSplitPayments', updateData, { root: true })
              commit('freelancers/updateFlatBookingSplitPayments', updateData, { root: true })
              resolve()
            } else {
              reject(new Error('Could not complete flat rate booking payment request'))
            }
          })
          .catch(() => {
            reject(new Error('Error in flat rate booking payment request'))
          })
      })
    }
  }
}
