export const login = {
  name: 'Login',
  path: '/login'
}

export const adminLogin = {
  name: 'AdminLogin',
  path: '/admin-login'
}

export const agencyLogin = {
  name: 'AgencyLogin',
  path: '/sso'
}

export const agencyRegister = {
  name: 'AgencyRegister',
  path: '/sso-register'
}

export const agencyWelcome = {
  name: 'AgencyWelcome',
  path: '/onboarding/welcome'
}

export const agencyOnboarding = {
  name: 'AgencyOnboarding',
  path: '/onboarding'
}

export const agencyProfileEdit = {
  name: 'AgencyProfileEdit',
  path: '/edit-agency-profile'
}

export const freelancerRegister = {
  name: 'FreelancerRegister',
  path: '/register'
}

export const freelancerResetPassword = {
  name: 'FreelancerResetPassword',
  path: '/reset-password'
}

export const freelancerWelcome = {
  name: 'FreelancerWelcome',
  path: '/create-profile/welcome'
}

export const freelancerOnboarding = {
  name: 'FreelancerOnboarding',
  path: '/create-profile'
}

export const freelancerProfileEdit = {
  name: 'FreelancerProfileEdit',
  path: '/edit-profile'
}

export const home = {
  name: 'Home',
  path: '/'
}

export const dashboard = {
  name: 'Dashboard',
  path: '/dashboard'
}

export const projects = {
  name: 'Projects',
  path: '/projects'
}

export const projectDetail = {
  name: 'ProjectSingle',
  path: '/projects/:id'
}

export const bookings = {
  name: 'Bookings',
  path: '/bookings'
}

export const freelancers = {
  name: 'Freelancers',
  path: '/freelancers'
}

export const freelancerDetail = {
  name: 'FreelancerSingle',
  path: '/freelancers/:id'
}

export const agencies = {
  name: 'Agencies',
  path: '/agencies'
}

export const agencyDetail = {
  name: 'AgencySingle',
  path: '/agencies/:id'
}

export const agencyEditAdmin = {
  name: 'AgencyEditAdmin',
  path: '/agencies/:id/edit'
}

export const timeRecording = {
  name: 'TimeRecordings',
  path: '/time-recording'
}

export const flatRatePayments = {
  name: 'FlatRatePayments',
  path: '/flat-rate-payments'
}

export const creditNotes = {
  name: 'CreditNotes',
  path: '/credit-notes'
}

export const users = {
  name: 'Users',
  path: '/users'
}

export const userSettings = {
  name: 'userSettings',
  path: '/users/:id'
}

export const profile = {
  name: 'Profile',
  path: '/profile'
}

export const settings = {
  name: 'Settings',
  path: '/settings'
}

export const about = {
  name: 'About',
  path: '/about'
}

export const termsAndConditions = {
  name: 'TermsAndConditions',
  path: '/terms-and-conditions'
}

export const dataPrivacy = {
  name: 'DataPrivacy',
  path: '/data-privacy'
}

export const imprint = {
  name: 'Imprint',
  path: '/imprint'
}

export const contact = {
  name: 'Contact',
  path: '/contact'
}

export const creditMemoProcedure = {
  name: 'CreditMemoProcedure',
  path: '/credit-memo-procedure'
}

export const agencySelection = {
  name: 'agencySelection',
  path: '/choose-agency'
}

export const reports = {
  name: 'reports',
  path: '/reports'
}

export const agencyGroups = {
  name: 'agency-groups',
  path: '/agency-groups'
}

export default {
  login,
  adminLogin,
  agencyLogin,
  agencyRegister,
  agencyWelcome,
  agencyOnboarding,
  agencyProfileEdit,
  freelancerRegister,
  freelancerResetPassword,
  freelancerWelcome,
  freelancerOnboarding,
  freelancerProfileEdit,
  home,
  dashboard,
  projects,
  projectDetail,
  bookings,
  freelancers,
  freelancerDetail,
  agencies,
  agencyDetail,
  agencyEditAdmin,
  timeRecording,
  flatRatePayments,
  creditNotes,
  users,
  userSettings,
  profile,
  settings,
  about,
  termsAndConditions,
  dataPrivacy,
  imprint,
  contact,
  creditMemoProcedure,
  agencySelection,
  reports,
  agencyGroups
}
