import axios from 'axios'

const state = () => ({
  freelancers: [],
  freelancerDetail: null,
  loading: false,
  currentPage: 1,
  isFinished: false
})

export default {
  namespaced: true,
  state,
  getters: {

  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    resetFreelancers (state) {
      state.currentPage = 1
      state.isFinished = false
      state.freelancers = []
    },
    toggleLoading (state, status) {
      state.loading = status
    },
    setFreelancers (state, data) {
      state.isFinished = state.currentPage >= data.total_pages
      if (state.currentPage === 1) {
        state.freelancers = data.data
      } else {
        state.freelancers = [...state.freelancers, ...data.data]
      }
    },
    incrementPage (state) {
      state.currentPage++
    },
    addFreelancer (state, freelancer) {
      state.freelancers.splice(0, 0, freelancer)
    },
    addFreelancerDetail (state, freelancer) {
      if (state.freelancerDetail && state.freelancerDetail.id === freelancer.id) {
        state.freelancerDetail = { ...state.freelancerDetail, ...freelancer }
      } else {
        state.freelancerDetail = freelancer
      }

      if (state.freelancers.length) {
        const index = state.freelancers.findIndex(({ id }) => id === freelancer.id)
        if (index !== -1) {
          const old = state.freelancers.find(({ id }) => id === freelancer.id)
          state.freelancers.splice(index, 1, { ...old, ...freelancer })
        }
      }
    },
    resetFreelancerDetail (state) {
      state.freelancerDetail = null
    },
    addBookmark (state, { freelancerId, bookmarkId }) {
      const freelancer = state.freelancers.find(({ id }) => id === freelancerId)
      if (freelancer) {
        freelancer.bookmark_id = bookmarkId
      }

      if (state.freelancerDetail?.id === freelancerId) {
        state.freelancerDetail.bookmark_id = bookmarkId
      }
    },
    removeBookmark (state, freelancerId) {
      const freelancer = state.freelancers.find(({ id }) => id === freelancerId)
      if (freelancer) {
        freelancer.bookmark_id = null
      }

      if (state.freelancerDetail?.id === freelancerId) {
        state.freelancerDetail.bookmark_id = null
      }
    },
    updateBookingList (state, booking) {
      if (state.freelancerDetail) {
        const index = state.freelancerDetail.bookings.findIndex(({ id }) => id === booking.id)
        if (index !== -1) {
          state.freelancerDetail.bookings.splice(index, 1, booking)
        } else {
          state.freelancerDetail.bookings.splice(0, 0, booking)
        }
      }
    },
    updateBookingStatus (state, booking) {
      if (state.freelancerDetail?.id === booking.freelancer_id) {
        const item = state.freelancerDetail.bookings.find(({ id }) => booking.id === id)
        if (item) {
          item.status = booking.status
        } else {
          state.freelancerDetail.bookings.splice(0, 0, booking)
        }
      }
    },
    updateVendorNumber (state, data) {
      if (state.freelancerDetail?.id === data.user_id) {
        state.freelancerDetail.vendor_number = data.vendor_number
      }
    },
    updateFlatBookingSplitPayments (state, { bookingId, freelancerId, amount }) {
      if (state.freelancerDetail?.id === freelancerId) {
        const item = state.freelancerDetail.bookings.find(({ id }) => id === bookingId)
        if (item) {
          item.amount_left -= amount
        }
      }
    }
  },
  actions: {
    loadFreelancers ({ state, commit, rootGetters }, search) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        const isSearch = typeof search !== 'undefined'

        if (isSearch) {
          params.append('page', 1)
          params.append('items', 50)
          params.append('keyword[]', search)
        } else {
          const filters = rootGetters['freelancersFilter/finalFilter']

          Object.keys(filters).forEach(key => {
            const val = filters[key]
            if (Array.isArray(val)) {
              val.forEach((item, index) => {
                params.append(`${key}[]`, ['clients', 'skills'].includes(key) ? item.name.toLowerCase() : item)
              })
            } else {
              params.append(key, val)
            }
          })

          params.append('page', state.currentPage)

          commit('toggleLoading', true)
        }

        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}list?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              if (!isSearch) {
                commit('setFreelancers', data)
                commit('toggleLoading', false)
              }
              resolve(data.data)
            } else {
              reject(new Error('Error loading freelancers'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading freelancers'))
          })
      })
    },
    reloadFreelancers ({ commit, dispatch }) {
      commit('resetFreelancers')
      dispatch('loadFreelancers')
    },
    loadFreelancersPage ({ commit, dispatch }) {
      commit('incrementPage')
      dispatch('loadFreelancers')
    },
    addBookmark ({ commit }, freelancer) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        params.append('freelancer_user_id', freelancer.id)

        axios.post(`${process.env.VUE_APP_FREELANCER_API_URL}bookmark/add`, params)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('addBookmark', { freelancerId: freelancer.id, bookmarkId: data.data.id })
              resolve()
            } else {
              reject(new Error('Error adding bookmark'))
            }
          })
      })
    },
    removeBookmark ({ commit }, freelancer) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        params.append('id', freelancer.bookmark_id)

        axios.post(`${process.env.VUE_APP_FREELANCER_API_URL}bookmark/remove`, params)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('removeBookmark', freelancer.id)
              resolve()
            } else {
              reject(new Error('Error removing bookmark'))
            }
          })
      })
    },
    loadFreelancerDetail ({ commit }, freelancerId) {
      return new Promise((resolve, reject) => {
        commit('resetFreelancerDetail')
        const params = new URLSearchParams()

        params.append('id', freelancerId)

        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}user?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('addFreelancerDetail', data.data)
              resolve(data.data)
            } else {
              reject(new Error('Error loading freelancer detail'))
            }
          })
      })
    },
    updateVendorNumber ({ commit }, form) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        Object.keys(form).forEach(key => {
          params.append(key, form[key])
        })

        axios.put(`${process.env.VUE_APP_FREELANCER_API_URL}vendor-number/update`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('updateVendorNumber', data.data)
              resolve()
            } else {
              reject(new Error('Error on updating freelancer vendor number'))
            }
          })
          .catch(() => {
            reject(new Error('Error on updating freelancer vendor number'))
          })
      })
    },
    deleteFreelancer ({ commit }, id) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('id', id)
        axios.delete(`${process.env.VUE_APP_FREELANCER_API_URL}delete-user?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve()
            } else {
              reject(new Error('Error on deleting freelancer'))
            }
          })
          .catch(() => {
            reject(new Error('Error on deleting freelancer'))
          })
      })
    }
  }
}
