/* eslint-disable camelcase */
import cloneDeep from 'lodash/cloneDeep'
import { timeReviewFilterTabs } from '@/helpers/constants'
import { addSearchEvent, addFilterEvent } from '@/helpers/gtm'

const timeRecordingStatusKeys = {}
Object.keys(timeReviewFilterTabs).forEach(key => (timeRecordingStatusKeys[timeReviewFilterTabs[key]] = key))

const state = () => ({
  filter: {
    keyword: [],
    freelancers: [],
    agencies: [],
    bookers: []
  },
  selectedTab: timeReviewFilterTabs.pending
})

export default {
  namespaced: true,
  state,
  getters: {
    hasAnyFilterApplied ({ filter }) {
      const { keyword, freelancers, agencies, bookers } = filter
      return !!(keyword.length || freelancers.length || agencies.length || bookers.length)
    },
    filterTags ({ filter }) {
      const { keyword, freelancers, agencies, bookers } = filter
      const tags = []

      if (freelancers.length) {
        freelancers.forEach(value => {
          const tag = { filter: 'freelancers', value: `${value.first_name} ${value.last_name}`, id: value.id }
          tags.push(tag)
        })
      }

      if (agencies.length) {
        agencies.forEach(value => {
          const tag = { filter: 'agencies', value: value.name, id: value.id }
          tags.push(tag)
        })
      }

      if (bookers.length) {
        bookers.forEach(value => {
          const tag = { filter: 'bookers', value: `${value.first_name} ${value.last_name}`, id: value.id }
          tags.push(tag)
        })
      }

      keyword.forEach(value => {
        const tag = { filter: 'keyword', value }
        tags.push(tag)
      })

      return tags
    },
    finalFilter ({ filter, selectedTab }, getters, rootState, rootGetters) {
      const finalFilter = cloneDeep(filter)
      if (!getters.hasAnyFilterApplied) {
        const { pending, rejected, approved } = timeReviewFilterTabs
        switch (selectedTab) {
        case timeReviewFilterTabs.pending:
          finalFilter.status = pending
          break
        case timeReviewFilterTabs.rejected:
          finalFilter.status = rejected
          break
        case timeReviewFilterTabs.approved:
          finalFilter.status = approved
          break
        }
      }
      return finalFilter
    }
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    switchTab (state, tab) {
      state.selectedTab = tab
    },
    updateFilter (state, { agencies, bookers, freelancers }) {
      state.filter.freelancers = freelancers
      state.filter.bookers = bookers
      state.filter.agencies = agencies
    },
    addKeyword (state, keyword) {
      state.filter.keyword.push(keyword)
    },
    removeFilter (state, { filter, value, id }) {
      console.log(filter, value, id)
      if (state.filter[filter]) {
        if (['freelancers', 'agencies', 'bookers'].includes(filter)) {
          const index = state.filter[filter].findIndex(item => id === item.id)
          index !== -1 && state.filter[filter].splice(index, 1)
        } else {
          const index = state.filter[filter].findIndex(item => value === item)
          index !== -1 && state.filter[filter].splice(index, 1)
        }
      }
    }
  },
  actions: {
    switchTab ({ state, commit, dispatch }, tab) {
      commit('switchTab', tab)
      dispatch('timeRecordingReview/reloadItems', null, { root: true })
    },
    updateFilter ({ commit, dispatch }, data) {
      commit('updateFilter', data)
      addFilterEvent()
      dispatch('timeRecordingReview/reloadItems', null, { root: true })
    },
    addKeyword ({ commit, dispatch }, keyword) {
      commit('addKeyword', keyword)
      addSearchEvent(keyword)
      dispatch('timeRecordingReview/reloadItems', null, { root: true })
    },
    removeFilter ({ commit, dispatch }, data) {
      commit('removeFilter', data)
      dispatch('timeRecordingReview/reloadItems', null, { root: true })
    }
  }
}
