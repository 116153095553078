import axios from 'axios'
import { itemsPerPage } from '@/helpers/constants'
import moment from 'moment'
const state = () => ({
  perPageItems: itemsPerPage,
  currentPage: 1,
  loading: false,
  sorting: {
    by: 'date',
    order: 'DESC'
  },
  creditNotes: [],
  total: null
})

export default {
  namespaced: true,
  state,
  getters: {
    creditNotes ({ creditNotes }) {
      return creditNotes.map(item => ({
        ...item,
        submitted_date: moment(item.created_at).format('DD.MM.YYYY')
      }))
    }
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    toggleLoading (state, status) {
      state.loading = status
    },
    setItems (state, { data, total }) {
      state.creditNotes = data
      state.total = total
    },
    setPage (state, { page, itemsPerPage }) {
      state.currentPage = page
      state.perPageItems = itemsPerPage
    },
    updateSort (state, data) {
      state.sorting = data
    },
    resetItems (state) {
      state.currentPage = 1
      state.creditNotes = []
    }
  },
  actions: {
    loadCreditNotes ({ state, commit, rootGetters }, freelancerId = null) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('items', state.perPageItems)
        const { by, order } = state.sorting
        params.append('sortBy', by)
        params.append('sort', order)
        params.append('page', state.currentPage)

        if (freelancerId) {
          params.append('freelancers[]', freelancerId)
        } else {
          const filters = rootGetters['creditNoteFilter/finalFilter']
          Object.keys(filters).forEach(key => {
            const val = filters[key]
            if (Array.isArray(val)) {
              if (key === 'agencies' || key === 'freelancers') {
                val.forEach((item) => {
                  params.append(`${key}[]`, item.id)
                })
              } else {
                val.forEach((item) => {
                  params.append(`${key}[]`, item)
                })
              }
            } else if (val) {
              params.append(key, val)
            }
          })
        }

        commit('toggleLoading', true)
        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}credit-note?${params.toString()}`)
          .then(({ data }) => {
            commit('toggleLoading', false)
            if (data.status === 'success') {
              commit('setItems', data.data)
              resolve(data.data)
            } else {
              reject(new Error('Error loading credit notes'))
            }
          }).catch(() => {
            commit('toggleLoading', false)
            reject(new Error('Error loading credit notes'))
          })
      })
    },
    async updateCreditNotesPage ({ commit, dispatch }, { options: { page, itemsPerPage }, freelancerId = null }) {
      await commit('setPage', { page, itemsPerPage })
      await dispatch('loadCreditNotes', freelancerId)
    },
    async sortCreditNotes ({ commit, dispatch }, { sorting, freelancerId = null }) {
      await commit('updateSort', sorting)
      await dispatch('loadCreditNotes', freelancerId)
    },
    reloadCreditNotes ({ commit, dispatch }) {
      commit('resetItems')
      dispatch('loadCreditNotes')
    },
    cancelCreditNote ({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}credit-note/cancel`, data)
          .then(({ data }) => {
            dispatch('loadCreditNotes')
            resolve(data.data)
          }).catch((error) => {
            reject(error)
          })
      })
    }
  }
}
